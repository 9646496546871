/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const DEFAULT_PROMPT = 'Enter Keyword or Product Number';
const DEFAULT_PROMPTRAISED = 'Search Keyword or Product Number';
const DEFAULT_PROMPTHOME = 'What are you looking for?';

const SearchContainer = ({ updatePromptMessage }) => {
    const brand = useSelector(getBrand);
    const pageType = useSelector(getCurrentPageType);
    const searchBarPlaceholder = useSelector(getFeatureFlag('searchbar-placeholder'));
    const isBot = useSelector(getIsBot);

    useEffect(() => {
        if ((!searchBarPlaceholder?.sf && typeof window !== 'undefined') || isBot) {
            updatePromptMessage(DEFAULT_PROMPT, DEFAULT_PROMPTHOME, DEFAULT_PROMPTRAISED);
        }
    }, [isBot, searchBarPlaceholder]);

    const { data, error } = useSalesforcePersonalizedContentQuery({
        queryName: 'searchBarPlaceholder',
        interactionName: `${brand.domain} - Get Campaign - Search Bar placeholder`,
        featureFlag: searchBarPlaceholder?.sf,
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
    });

    if (error) {
        mbpLogger.logError({
            function: `SearchContainer - interactionName = ${brand.domain} - Get Campaign - Search Bar placeholder`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'searchBarPlaceholder',
            jsError: error,
        });
    }

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: pageType },
    });

    const { campaign } = data?.contentSF || {};
    if (campaign?.campaignResponses?.length <= 0) {
        updatePromptMessage(DEFAULT_PROMPT, DEFAULT_PROMPTHOME, DEFAULT_PROMPTRAISED);
        return null;
    }

    const PROMPT = searchBarPlaceholder?.placeholder?.length > 0 ? searchBarPlaceholder?.placeholder[0] : DEFAULT_PROMPT;
    const PROMPTHOME = searchBarPlaceholder?.placeholderClicked?.length > 0 ? searchBarPlaceholder?.placeholderClicked[0] : DEFAULT_PROMPTHOME;
    const experienceName = campaign?.campaignResponses?.[0]?.experienceName;

    useEffect(() => {
        if (experienceName === 'Custom') {
            updatePromptMessage(PROMPT, PROMPTHOME, PROMPT);
        }
        if (experienceName === 'Default' || error) {
            updatePromptMessage(DEFAULT_PROMPT, DEFAULT_PROMPTHOME, DEFAULT_PROMPTRAISED);
        }
    }, [experienceName, error]);
    return null;
};

SearchContainer.propTypes = {
    updatePromptMessage: func,
};

SearchContainer.defaultProps = {
    updatePromptMessage: () => {},
};

export default SearchContainer;
