/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

const getAutoSuggest = ({ targetingEnabled, brand, phrase }, locale = 'en-us') => {
    let res = null;
    if (brand && phrase) {
        res = gql` query autoSuggest${targetingEnabled ? '($targeting: [Targeting])' : '' } {
            autoSuggest(brand: "${brand}", environment: "${GRAPHQL_ENV}", phrase: "${phrase}", locale: "${locale}"${targetingEnabled ? ', targeting: $targeting' : ''}) {
                codes,
                phrases
                products{
                    partNumber,
                    seo{
                        url
                    },
                    name,
                    image{ name, path },
                    prices{ type, value },
                    skuPriceRange{ sale{ value }, retail{ value } },
                    productSkus { id }
                }
            }
        }`;
    }
    return res;
};

export default getAutoSuggest;
