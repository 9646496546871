/*
    * Confidential and Proprietary.
    * Do not distribute without 1-800-Flowers.com, Inc. consent.
    * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
    */
import React, { useEffect } from 'react';
import {
    object, func, bool, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import mbpLogger from 'mbp-logger';
import { clearSearchResult } from '../../../../../state/ducks/App/App-Actions';

const useStyles = makeStyles((theme) => ({
    topSearchHeader: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '0px',
        '& li': {
            padding: '5px 0px',
            '& a': {
                textDecoration: 'none',
                color: '#3c3c35',
                fontSize: '15px',
                padding: '5',
            },
        },
    },
    searchResultContainer: {
        position: 'absolute',
        zIndex: '25',
        background: '#fff',
        top: '45px',
        width: '100%',
        border: 'solid 1px #d8d8d8',
        borderTop: 'none',
        borderBottomRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
        maxHeight: 'calc(100vh - 102px)',
        overflowY: 'auto',
    },
    productSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '3px 0',
        '& li': {
            display: 'inline-block',
            minHeight: '80px',
            maxHeight: '80px',
            width: '100%',
            marginBottom: '0px',
            '& a': {
                padding: '4px 0',
                marginBottom: '10px',
                color: '#3c3c35',
                textDecoration: 'none',
                wordWrap: 'break-word',
                display: 'block',
                fontSize: '14px',
                '& img': {
                    float: 'left',
                    width: 'calc(52px + 12px)',
                    paddingRight: '12px',
                    display: 'block',
                },
                '& span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'block',
                },
            },
        },
    },
    thumbName: {
        padding: '5px 0',
    },
    thumbPrice: {
        padding: '5px 0 10px',
        fontWeight: 600,
    },
    borderRight: {
        borderRight: `${'1px solid'} ${theme.palette.cms?.bloomreachBorderColor || '#d8d8d8'}`,
        [theme.breakpoints.down('xs')]: {
            borderRight: 0,
        },
    },
}));

const SearchResultDropDown = ({
    concatSearchTerm, clearFunc, suggest, phrase, showClearIcon,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const clearData = (persistValue = false, searchTerm = null) => {
        dispatch(clearSearchResult(persistValue, searchTerm));
        clearFunc(persistValue, searchTerm);
    };
    useEffect(() => {
        /*
            `   When a user clears search, showIcon will be set to false
                When the search is cleared we need to clear the suggest dropdown
            */
        if (showClearIcon === false) {
            clearData();
        }
    }, [showClearIcon]);

    const showValueMax = (name, link, searchTerm, key) => {
        if (key < 5) {
            return (
                <li key={name}>
                    <Link
                        to={`/searchterm/${link}`}
                        onClick={() => {
                            clearData(true, searchTerm);
                        }}
                    >
                        {ReactHtmlParser(name)}
                    </Link>
                </li>
            );
        }
        return null;
    };

    const suggestionFunc = (sugg, value) => {
        let suggArray = '';
        if (typeof sugg !== 'undefined' && sugg.length > 0) {
            const searchVal = value.toLowerCase();
            suggArray = sugg.map((row) => {
                const data = {};
                data.inputString = row.dq.toLowerCase();
                data.name = row.dq.toLowerCase().replace(searchVal, `<b>${searchVal}</b>`);
                data.link = concatSearchTerm(row.dq.replace(/\s+/g, '+'), row.dq);
                return data;
            });
        }
        if (typeof suggArray !== 'undefined' && suggArray.length > 0) {
            return suggArray.map((data, key) => showValueMax(data.name, data.link, data.inputString, key));
        }
        return [];
    };

    const getPath = (url, title) => {
        let retVal = null;
        try {
            retVal = new URL(url).pathname;
        } catch (e) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                jsError: e,
                message: `SearchResultDropDown: Invalid url "${url}" in suggestion "${title}"`,
            });
            retVal = null;
        }
        return retVal;
    };

    return (
        <div className={classes.searchResultContainer}>
            <Grid container>
                <Grid item sm={4} xs={12} className={classes.borderRight}>
                    <ul className={classes.topSearchHeader}>
                        {suggestionFunc(suggest.suggestions, suggest.q)}
                    </ul>
                </Grid>
                <Grid item sm={8} xs={12}>
                    <ul className={classes.productSuggestions}>
                        {suggest.products.map((data, key) => {
                            if (key >= 4) return null;  // only want to show first 4
                            const path = getPath(data.url, data.title);
                            if (!path) return null;  // likely suggestion's url is bad
                            return (
                                <li key={data.pid}>
                                    <Link
                                        to={concatSearchTerm(path, phrase)}
                                        onClick={() => {
                                            clearData();
                                        }}
                                        title={ReactHtmlParser(data.title)}
                                    >
                                        <img data-testid={`${'image-'}${data.pid}`} alt={ReactHtmlParser(data.title)} className="thumb-image" src={data.thumb_image} />
                                        <span data-testid={`${'title-'}${data.pid}`} className={classes.thumbName}>{ReactHtmlParser(data.title)}</span>
                                        <span data-testid={`${'price-'}${data.pid}`} className={classes.thumbPrice}>{`${'$'}${data.sale_price}`}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </Grid>
            </Grid>
        </div>
    );
};

SearchResultDropDown.propTypes = {
    suggest: object.isRequired,
    showClearIcon: bool.isRequired,
    phrase: string,
    concatSearchTerm: func,
    clearFunc: func,
};

SearchResultDropDown.defaultProps = {
    phrase: '',
    concatSearchTerm: () => {},
    clearFunc: () => {},
};

export default SearchResultDropDown;
